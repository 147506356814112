import { render, staticRenderFns } from "./piece_work_wage_list.vue?vue&type=template&id=397be5b0&scoped=true"
import script from "./piece_work_wage_list.vue?vue&type=script&lang=js"
export * from "./piece_work_wage_list.vue?vue&type=script&lang=js"
import style0 from "./piece_work_wage_list.vue?vue&type=style&index=0&id=397be5b0&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "397be5b0",
  null
  
)

export default component.exports