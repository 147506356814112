<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="日期范围" colspan="2">
					<c-datetime type="date" start-name="start_date" end-name="end_date" range></c-datetime>
				</m-search-item>
				<m-search-item label="员工姓名">
					<c-input name="user_realname"></c-input>
				</m-search-item>
				<m-search-item label="手机号">
					<c-input name="user_phone"></c-input>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('b_piece_work_wage_export')" color="sys" @click="exportData">导出</c-button>
			</m-operate>
			
			<c-table ref="table" height="grow">
				<c-table-column
					label="员工姓名"
					name="user_realname"
					width="120"
				>
				</c-table-column>

				<c-table-column
					label="手机号"
					name="user_phone"
					width="110"
				>
				</c-table-column>

				<c-table-column
					label="报工数量（件）"
					name="product_count"
					width="110"
				>
					<template v-slot="{data}">
						<span v-if="data.product_count" style="color: green">{{data.product_count}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>

				<c-table-column
					label="员工计件工资"
					name="product_money"
					width="130"
				>
					<template v-slot="{data}">
						<span v-if="data.product_money" style="color: green">￥{{data.product_money}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>


				<c-table-column
					type="button"
					fixed="right"
					label="操作"
					width="120"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_piece_work_wage_view')" @click="detail(data)">报工明细</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
		<!--LOGO列表 -->
		<c-dialog ref="detailDialog" title="报工明细" width="1105">
			<c-detail column="2" style="max-width:1100px;">
				<c-detail-item label="日期范围：">
					{{detailData.start_date}} 至 {{detailData.end_date}}
				</c-detail-item>
				<c-detail-item label="员工姓名：">
					{{detailData.user_realname}}
				</c-detail-item>
				<c-detail-item label="员工手机：">
					{{detailData.user_phone}}
				</c-detail-item>
				<c-detail-item label="计件工资合计：">
					￥{{detailData.product_money}}
				</c-detail-item>
			</c-detail>
			<c-detail style="max-width:1100px;">
				<c-table ref="recordTable" :data="recordList" :paging="false" border>
					<c-table-column
						label="报工日期"
						width="100"
						name="work_date"
					>
					</c-table-column>
					<c-table-column
						label="产品"
						width="100"
						name="product_name"
					>
					</c-table-column>

					<c-table-column
						label="工序"
						width="100"
						name="processes_name"
					>
					</c-table-column>
					<c-table-column
						label="报工数量"
						width="100"
						name="product_count"
					>
					</c-table-column>
					<c-table-column
						label="工序用工单价"
						width="100"
						name="product_price"
					>
						<template v-slot="{data}">
							￥{{data.product_price}}
						</template>
					</c-table-column>
					<c-table-column
						label="计件工资"
						width="100"
						name="product_money"
					>
						<template v-slot="{data}">
							￥{{data.product_money}}
						</template>
					</c-table-column>
					<c-table-column
						label="操作人"
						width="100"
						name="create_user_realname"
					>
					</c-table-column>
					<c-table-column
						label="操作时间"
						width="140"
						name="create_date"
					>
					</c-table-column>
				</c-table>
			</c-detail>
		</c-dialog>
	</page>
</template>

<script>
	export default {
		name: 'm_piece_work_wage',
		
		data() {
			return {
				detailData:{},
				recordList: [],
				formData:null
			}
		},
		
		computed: {
		},
		
		mounted() {
			let now = new Date();
			this.$refs.search.set({
				start_date: new Date(now.getFullYear(), now.getMonth(), 1).format('yyyy-MM-dd'),
				end_date: new Date(now.getFullYear(), now.getMonth() + 1, 0).format('yyyy-MM-dd')
			})
			this.$refs.search.search();
		},
		
		methods: {
			searchHandle(data) {
				this.$refs.table.load({
					url: 'piece_work_wage/list',
					data
				});
				this.formData = data;
			},

			detail(data) {
				this.request({
					url: 'piece_work_wage/detail',
					data: {
						user_id: data.user_id,
						start_date: data.start_date,
						end_date: data.end_date
					},
					loading: true,
					success: data => {
						this.detailData = data;
						this.recordList = data.record_list;
						this.$refs.detailDialog.open();
					}
				});
			},

			exportData(){
				this.request({
					url: 'export/getURL',
					data: {
						template_id: 1      //工资数据
					},
					loading: true,
					success: data => {
						var export_url = data;
						if (this.formData && Object.keys(this.formData).length > 0){
							for (var key in this.formData) {
								if(this.formData[key] && this.formData[key]!=''){
									//添加提交参数
									export_url = export_url + "&";
									export_url = export_url + key +"="+this.formData[key];
								}
							}
						}
						window.open(export_url);
					}
				});
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.power {
		height: 300px;
		overflow: auto;
		
		&-list {
			clear: both;
			overflow: hidden;
			
			.power-list {
				margin-left: 40px;
				margin-bottom: 20px;
			}
		}
		
		&-item {
			float: left;
			margin-top: 15px;
			margin-left: 20px;
		}
	}
</style>